import {configureStore as baseConfigureStore, type Selector as ReduxSelector} from '@reduxjs/toolkit';
import {jobBoardApi} from 'job-board/api/jobBoardApi';
import rootReducer from './rootReducer';

export const configureStore = (preloadedState?: Record<string, unknown>) => {
  const store = baseConfigureStore({
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(jobBoardApi.middleware),
    preloadedState,
    reducer: rootReducer
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
};

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type StoreDispatch = typeof store.dispatch;
export type Selector<T> = ReduxSelector<RootState, T>;
