import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import arrowLeft from 'assets/svg/smallArrowLeft12.svg?sprite';
import TextBody from 'shared/ui/atoms/text/body';
import {routesPaths} from 'job-board/routes/constants';
import Svg from 'job-board/shared/components/svg';
import styles from './backButton.scss';

const BackButton = (): JSX.Element => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      className={styles['back-button']}
      data-ui="header-back-button"
      onClick={() => navigate(routesPaths.PROFILE.index, {replace: true})}
    >
      <Svg className={styles['arrow-left']} id={arrowLeft.id} />
      <TextBody strong>{t('header.back-button.label')}</TextBody>
    </button>
  );
};

export default memo(BackButton);
