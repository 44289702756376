import React, {type ReactNode, Suspense, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {useHasServerError} from 'job-board/contexts/serverError/hooks';
import {HeapAction, PageViewError} from 'job-board/shared/analytics/events/heap';
import Loader from 'job-board/shared/components/loader';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import styles from './serverErrorContainer.scss';

const SomethingWentWrong = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.somethingWentWrong' */ '../somethingWentWrong'),
  'component.somethingWentWrong'
);

type ServerErrorContainerProps = {
  children: ReactNode;
  hasServerError?: boolean;
};

const ServerErrorContainer = ({
  children,
  hasServerError: initialHasServerError
}: ServerErrorContainerProps): JSX.Element => {
  const {t} = useTranslation();
  const hasServerError = useHasServerError();
  const {heapEvent} = useAnalytics();
  const handleErrorButton = useCallback(() => window.location.reload(), []);

  const hasError = initialHasServerError || hasServerError;

  useEffect(() => {
    if (hasError) {
      heapEvent(HeapAction.View_Error_Page, {errorName: PageViewError.SERVICE_UNAVAILABLE});
    }
  }, [hasError]);

  return hasError ? (
    <Suspense fallback={<Loader />}>
      <SomethingWentWrong
        actionLabel={t('server-error.button')}
        className={styles.container}
        description={t('server-error.subtitle')}
        onActionClick={handleErrorButton}
        title={t('server-error.title')}
      />
    </Suspense>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export default ServerErrorContainer;
