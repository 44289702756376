import React, {Component, type ErrorInfo, type ReactNode} from 'react';
import type Rollbar from 'rollbar';
import {useRollbar} from 'job-board/contexts/rollbar/hooks';

type Props = {
  children: ReactNode;
  rollbar: Rollbar | null;
};

class ErrorBoundary extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const {rollbar} = this.props;

    if (rollbar) {
      rollbar.error(error, errorInfo);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  render(): ReactNode {
    const {children} = this.props;

    return children;
  }
}

const ErrorBoundaryWithRollbar = ({children}: {children: ReactNode}): JSX.Element => {
  const rollbar = useRollbar();

  return <ErrorBoundary rollbar={rollbar}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWithRollbar;
