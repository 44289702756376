import datadog, {DatadogClient, DatadogOptions} from 'shared/helpers/analytics/datadog';
import {isTestEnv} from 'job-board/shared/utils/env';
import {watchForConsent} from '../helpers/trackConsent';

let datadogClient: DatadogClient;

export const createDatadogClient = (options: DatadogOptions): Promise<DatadogClient | undefined> => {
  if (!__DD_APP_ID__ || !__DD_CLIENT_TOKEN__) {
    return Promise.resolve(undefined);
  }

  const consentCheck = isTestEnv() ? (): Promise<true> => Promise.resolve(true) : watchForConsent;

  return consentCheck().then(hasGivenConsent => {
    if (!hasGivenConsent) {
      return undefined;
    }

    datadogClient = datadogClient || datadog(options);

    return datadogClient;
  });
};
