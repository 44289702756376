import React from 'react';
import {useTranslation} from 'react-i18next';
import sparksAi from 'assets/svg/sparksAi16.svg?sprite';
import clsx from 'clsx';
import PillLabel from 'shared/ui/atoms/pill/label';
import Svg from 'job-board/shared/components/svg';
import styles from './forYou.scss';

type ForYouPillProps = {
  className?: string;
};

export const ForYouPill = ({className}: ForYouPillProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <PillLabel ai className={clsx(styles['for-you-pill'], className)} data-ui="for-you">
      <Svg className={styles.icon} id={sparksAi.id} />
      {t('shared.search-input.job-title.for-you')}
    </PillLabel>
  );
};
