import React, {createContext, type ReactNode, useRef} from 'react';
import type Rollbar from 'rollbar';
import {register} from 'shared/helpers/rollbar';

const ignoreRules = [
  {
    matchers: [/Script error\./, /isCanceled/, /Loading chunk/, /Loading CSS chunk/, /Share canceled/],
    path: 'rollbarPayload.body.trace.exception.message'
  },
  {
    matchers: [/chrome-extension:/],
    path: 'rollbarPayload.body.trace.frames[0].filename'
  }
];

export const RollbarContext = createContext<Rollbar | null | undefined>(undefined);

export const RollbarProvider = ({children}: {children?: ReactNode}): JSX.Element => {
  const rollbar = useRef<Rollbar | null>(register({ignoreRules}));

  return <RollbarContext.Provider value={rollbar.current}>{children}</RollbarContext.Provider>;
};
