import type {FetchArgs} from '@reduxjs/toolkit/query';
import {nanoid} from 'nanoid';
import buildUrl from 'job-board/shared/utils/buildUrl';
import {SEARCH_ID_HEADER} from './constants';
import type {GetPaginatedJobsParams} from './types/getJobs';
import type {JobAttributionParams} from './types/jobAttribution';

export const getPaginatedJobsQuery = (queryParams: GetPaginatedJobsParams): FetchArgs => ({
  headers: {
    [SEARCH_ID_HEADER]: nanoid()
  },
  method: 'GET',
  url: buildUrl('/api/v1/jobs', queryParams)
});

export const jobAttributionQuery = ({id, position, searchId}: JobAttributionParams): FetchArgs => {
  const headers = searchId
    ? {
        headers: {
          [SEARCH_ID_HEADER]: searchId
        }
      }
    : {};

  return {
    body: {
      job: {
        position
      }
    },
    credentials: 'same-origin' as RequestCredentials,
    method: 'POST',
    url: `/api/v1/jobs/${id}/attribute`,
    ...headers
  };
};
