import {useContext} from 'react';
import {
  ServerErrorContext,
  type ServerErrorContextType,
  ServerErrorUpdaterContext,
  type ServerErrorUpdaterContextType
} from './provider';

export const useHasServerError = (): ServerErrorContextType => {
  const hasServerError = useContext(ServerErrorContext);

  if (typeof hasServerError === 'undefined') {
    throw new Error('useHasServerError must be used within a ServerErrorProvider');
  }

  return hasServerError;
};

export const useSetHasServerError = (): ServerErrorUpdaterContextType => {
  const setHasServerError = useContext(ServerErrorUpdaterContext);

  if (typeof setHasServerError === 'undefined') {
    throw new Error('useSetHasServerError must be used within a ServerErrorProvider');
  }

  return setHasServerError;
};
