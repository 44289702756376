import React from 'react';
import clsx from 'clsx';
import {Device, useDevice} from 'shared/contexts/device';
import ResponsiveProvider from 'shared/contexts/device/responsive';
import CircularLoader, {type CircularLoaderProps} from 'shared/ui/molecules/loader/circular';
import {MOBILE_FIRST_MEDIA_QUERIES} from 'job-board/shared/mediaQueries';
import styles from './loader.scss';

type LoaderProps = {
  isFixedCenter?: boolean;
  isResponsive?: boolean;
} & CircularLoaderProps;

const Loader = ({
  isFixedCenter = true,
  isResponsive = true,
  large,
  medium,
  small,
  ...rest
}: LoaderProps): JSX.Element => {
  const [device] = useDevice();
  const isMobile = device === Device.Mobile;

  const isLarge = large || (!small && !medium);

  return (
    <CircularLoader
      data-ui="loader"
      large={isResponsive ? isLarge && !isMobile : isLarge}
      medium={medium || (isResponsive && isMobile && isLarge)}
      small={small}
      {...rest}
      className={clsx(
        {
          [styles['fixed-center']]: !!isFixedCenter
        },
        rest.className
      )}
    />
  );
};

const ResponsiveLoader = (props: LoaderProps): JSX.Element => (
  <ResponsiveProvider mobileMediaQueries={MOBILE_FIRST_MEDIA_QUERIES}>
    <Loader {...props} />
  </ResponsiveProvider>
);

export default ResponsiveLoader;
