import {useContext} from 'react';
import {
  HasRouteChangedContext,
  type HasRouteChangedContextType,
  HasRouteChangedUpdaterContext,
  type HasRouteChangedUpdaterContextType
} from './provider';

export const useHasRouteChanged = (): HasRouteChangedContextType => {
  const hasRouteChanged = useContext(HasRouteChangedContext);

  if (typeof hasRouteChanged === 'undefined') {
    throw new Error('useHasRouteChanged must be used within a HasRouteChangedProvider');
  }

  return hasRouteChanged;
};

export const useSetHasRouteChanged = (): HasRouteChangedUpdaterContextType => {
  const setHasRouteChanged = useContext(HasRouteChangedUpdaterContext);

  if (typeof setHasRouteChanged === 'undefined') {
    throw new Error('useSetHasRouteChanged must be used within a HasRouteChangedProvider');
  }

  return setHasRouteChanged;
};
