import React, {createContext, type ReactNode, useState} from 'react';

export type ServerErrorContextType = boolean | undefined;
export const ServerErrorContext = createContext<ServerErrorContextType>(undefined);

export type ServerErrorUpdaterContextType = (hasServerError: boolean) => void;
export const ServerErrorUpdaterContext = createContext<ServerErrorUpdaterContextType | undefined>(undefined);

export const ServerErrorProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const [hasServerError, setHasServerError] = useState(false);

  return (
    <ServerErrorContext.Provider value={hasServerError}>
      <ServerErrorUpdaterContext.Provider value={setHasServerError}>{children}</ServerErrorUpdaterContext.Provider>
    </ServerErrorContext.Provider>
  );
};
