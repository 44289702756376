import React, {createContext, type ReactNode, useEffect, useState} from 'react';
import type {DatadogClient} from 'shared/helpers/analytics/datadog';
import {createDatadogClient} from 'job-board/shared/analytics/clients/datadog';

export const DatadogContext = createContext<DatadogClient | undefined>(undefined);

export const DatadogProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const [client, setClient] = useState<DatadogClient>();

  useEffect(() => {
    void createDatadogClient({
      applicationId: __DD_APP_ID__,
      clientToken: __DD_CLIENT_TOKEN__,
      service: 'job-board',
      sessionReplaySampleRate: 0,
      sessionSampleRate: 10,
      silentMultipleInit: true
    }).then(setClient);
  }, []);

  return <DatadogContext.Provider value={client}>{children}</DatadogContext.Provider>;
};
