import React, {createContext, type ReactNode, useState} from 'react';

export type HasRouteChangedContextType = boolean | undefined;
export const HasRouteChangedContext = createContext<HasRouteChangedContextType>(undefined);

export type HasRouteChangedUpdaterContextType = (hasRouteChanged: boolean) => void;
export const HasRouteChangedUpdaterContext = createContext<HasRouteChangedUpdaterContextType | undefined>(undefined);

export const HasRouteChangedProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const [hasRouteChanged, setHasRouteChanged] = useState(false);

  return (
    <HasRouteChangedContext.Provider value={hasRouteChanged}>
      <HasRouteChangedUpdaterContext.Provider value={setHasRouteChanged}>
        {children}
      </HasRouteChangedUpdaterContext.Provider>
    </HasRouteChangedContext.Provider>
  );
};
