import type {ValueOf} from 'shared/types/helpers';
import type {AnalyticsClient} from '../clients/gtm';

export type QueueOperation = {
  method: keyof AnalyticsClient;
  params: Parameters<ValueOf<AnalyticsClient>>;
};

export const createGtmClientQueue = (queue: QueueOperation[]): AnalyticsClient => ({
  addHeapEventProperties: (...args): void => {
    queue.push({
      method: 'addHeapEventProperties',
      params: args
    });
  },
  addHeapEventProperty: (...args): void => {
    queue.push({
      method: 'addHeapEventProperty',
      params: args
    });
  },
  gaEvent: (...args): void => {
    queue.push({
      method: 'gaEvent',
      params: args
    });
  },
  heapEvent: (...args): void => {
    queue.push({
      method: 'heapEvent',
      params: args
    });
  },
  heapIdentify: (...args): void => {
    queue.push({
      method: 'heapIdentify',
      params: args
    });
  },
  hotjarIdentify: (...args): void => {
    queue.push({
      method: 'hotjarIdentify',
      params: args
    });
  },
  pageview: (...args): void => {
    queue.push({
      method: 'pageview',
      params: args
    });
  },
  removeHeapEventProperties: (...args): void => {
    queue.push({
      method: 'removeHeapEventProperties',
      params: args
    });
  },
  removeHeapEventProperty: (...args): void => {
    queue.push({
      method: 'removeHeapEventProperty',
      params: args
    });
  },
  resetHeapIdentity: (...args): void => {
    queue.push({
      method: 'resetHeapIdentity',
      params: args
    });
  },
  resetHotjarIdentity: (...args): void => {
    queue.push({
      method: 'resetHotjarIdentity',
      params: args
    });
  },
  variable: (...args): void => {
    queue.push({
      method: 'variable',
      params: args
    });
  }
});

export type AnalyticsQueueClient = AnalyticsClient & {
  runQueue: (client: AnalyticsClient) => void;
};

export default (queue: QueueOperation[] = []): AnalyticsQueueClient => {
  const runQueue = (client: AnalyticsClient): void => {
    queue.forEach(({method, params}: QueueOperation) => {
      // TypeScript was being stubborn about the ...params part and no matter what I tried
      // I couldn't make it work, so I decided to kill it 🔫
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      client[method](...params);
    });
    queue.length = 0;
  };

  return {
    ...createGtmClientQueue(queue),
    runQueue
  };
};
